var magazine = {
	initialize(config) {
		let self = this,
			logoElement = document.getElementById('logoSvgMag'),
			target = document.getElementById('MagPagination');

		// Choix d'une rubrique
		for (const iterator of config.selects) {
			this.selection(iterator);
		}

		// Gestion du logo svg
		logoElement !== null && helpers.getWindowWidth() >= 1024 && this.logo(logoElement);

		// Infinite scroll
		const intersectionObserver = new IntersectionObserver(entries => {
			if (entries[0].intersectionRatio <= 0) return;
			self.getMorePosts(config.url, 'MagLines')
		});
		intersectionObserver.observe(target);
	},

	// Lors d'un select, on redirige vers la page correspondante
	selection(id) {
		document.getElementById(id).addEventListener('change', function (e) {
			let path = e.target.value !== '' ? `/magazine-voyage/${e.target.value}` : `/magazine-voyage/accueil`;
			window.location.href = window.location.protocol + '//' + window.location.host + path;
		});
	},
	// Logo sur la page d'accueil
	logo(el) {
		let src = el.src;
		fetch(src)
			.then((response) => {
				return response.text()
			})
			.then((body) =>
				el.parentElement.innerHTML = body
			);
	},
	// Fetch nouveaux articles
	getMorePosts(url, idContainer) {
		fetch(url)
			.then((response) => {
				return response.text()
			})
			.then((data) => {
				if (data == 'stop') {
					console.info(`Il n'y a plus d'articles à charger.`)
				} else {
					document.getElementById(idContainer).insertAdjacentHTML('beforeend', data);
				}
			})
			.catch(e => {
				console.error(e)
			})
	},

	// Article
	article() {

		/// Module de partage social
		var clickSocial = function (a, w, h) {
			window.open(a.attr('href'), 'popupWindow', 'menubar=no,toolbar=no,resizable=yes,scrollbars=yes,height=' + h + 'px,width=' + w + 'px');
		};
		document.querySelectorAll('[data-share]').forEach(element => {
			element.addEventListener('click', function () {
				var id = this.id;
				clickSocial($('#' + id), 500, 250);
				return false;
			});
		})

		///
		/// Clean style wysiwyg
		///

		// supprimer le <p> des citations et iframes
		var arrayBlockquote = document.querySelectorAll('.cms__texte blockquote'),
			arrayIframe = document.querySelectorAll('.cms__texte iframe');

		var removeWrapperTag = function (a) {
			for (var i = a.length - 1; i >= 0; i--) {
				a[i].innerHTML = a[i].innerText;
			}
		}
		removeWrapperTag(arrayBlockquote);

		for (var i = arrayIframe.length - 1; i >= 0; i--) {

			if (arrayIframe[i].getAttribute('src').includes('https://player.vimeo.com/') || arrayIframe[i].getAttribute('src').includes('https://www.youtube.com/embed')) {
				arrayIframe[i].parentElement.classList.add('is16by9');
				arrayIframe[i].parentElement.classList.add('embed-responsive');
			}
		}

		// wraper <figure> aux images du texte
		var figure = function () {
			var arrayImgs = document.querySelectorAll('.cms__texte img');
			for (var i = arrayImgs.length - 1; i >= 0; i--) {
				var legende = '';

				if (arrayImgs[i].alt != '')
					legende = '<figcaption>' + arrayImgs[i].alt + '</figcaption>';

				var wrapper = document.createElement('figure');
				arrayImgs[i].parentElement.before(wrapper);
				wrapper.append(arrayImgs[i]);
				wrapper.insertAdjacentHTML('beforeend', legende);

				if (wrapper.nextSibling.localName == 'p') {
					if (wrapper.nextSibling.innerText == '') {
						wrapper.nextSibling.remove();
					}
				}
			}
		}

		figure();

		// supprimer les styles en ligne
		var node = document.querySelectorAll('.cms__texte *');
		node.forEach(el => el.removeAttribute('style'));
	}
}